import { useTranslation } from 'react-i18next'
import { AuthSection } from '../../components'
import {
  Spacer,
  Typography,
  Button,
} from '@prometeoapi/afrodita-react-component-lib'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import styles from './reset-password.module.scss'
import { useResetPasswordUseCases } from 'src/service-locator'
import { useState } from 'react'
import { HeaderAuth, InputText } from 'src/components'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
interface IFormInput {
  email: string
}

export const ResetPasswordPage = () => {
  const { t } = useTranslation(['shared', 'resetpage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { resetPassword } = useResetPasswordUseCases()
  const [emailError, setEmailError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  const formSchema = Yup.object({
    email: Yup.string()
      .email(
        t('shared:form.errors.emailField', {
          name: t(`register:registerPage.emailInput`),
        })
      )
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.emailInput`),
        })
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(formSchema),
  })

  const resetPasswordAction = async (data: any) => {
    try {
      await resetPassword(data)
      setSuccess(true)
      reset()
    } catch (error: any) {
      if (errors) {
        setEmailError(error.response.data?.email || null)
      }
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    resetPasswordAction(data)
  }

  return (
    <div>
      <HeaderAuth />

      <section className={styles['page-container']}>
        <div
          className={success === true ? styles['row-success'] : styles['row']}
        >
          {success && (
            <div>
              <Spacer size={'10x'} />
              <Typography
                variant="body-1"
                withFontFamily
                typographyColor="green-7"
              >
                {t(`resetpage:resetPage.successMessage`)}
              </Typography>
            </div>
          )}
          <Spacer size={'10x'} />
          <Typography variant="display-3" withFontFamily>
            {t(`resetpage:resetPage.title`)}
          </Typography>
          <Spacer size={'10x'} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  type="email"
                  label={t(`resetpage:resetPage.emailInputh`)}
                  error={emailError || errors?.email?.message}
                />
              )}
            />
            {errorMessage ? (
              <p className={styles['error-text']}>{errorMessage} </p>
            ) : (
              ''
            )}
            <Spacer size={'10x'} />
            <div className={styles['button-container']}>
              <Button type={'submit'} fullWidth>
                {t(`resetpage:resetPage.title`)}{' '}
              </Button>
            </div>
          </form>
          <Spacer size={'10x'} />
          <AuthSection />
        </div>
      </section>
    </div>
  )
}
