export default {
  nav: {
    useCases: 'Casos de uso',
    security: 'Seguridad',
    documentation: 'Documentación',
    glossary: 'Glosario',
    reports: 'Reportes',
    resources: 'Recursos',
    blog: 'Blog',
    aboutPrometeo: 'Sobre Prometeo',
    aboutUs: 'Sobre Nosotros',
    workWithUs: 'Trabaja con Nosotros',
    faq: 'Preguntas frecuentes',
    helpCenter: 'Centro de Ayuda',
    products: 'Productos',
    team: 'Nuestro Equipo',
    privacyPolicy: 'Política De Privacidad',
    securityPolicy: 'Política De Seguridad',
    press: 'Prensa',
    medium: 'Medium',
    start: 'Comienza gratis',
    paymentApi: 'Pago por transferencia',
    paymentsQR: 'Cobros con QR',
    paymentslink: 'Pagos con link',
    dataApi: 'Gestión de la Tesorería',
    accountToAccountPayments: 'Pagos Cuenta a Cuenta',
    treasuryManagement: 'Gestión de la Tesorería',
    bankingInformationConsolidation: 'Consolidación de Información Bancaria',
    bankingInformationAccess: 'Acceso a Información Bancaria',
    bankAccountAuthentication: 'Validación de Cuentas Bancarias',
    qrPay: 'Pagá con tu banco',
    validationApi: 'Validación de cuentas bancarias',
    bank: 'Bancos',
    credits: 'Créditos y préstamos',
    ecommerce: 'E-commerce y marketplace',
    paymentGateway: 'Pasarelas de pagos',
    services: 'Servicios',
    partnersProgram: 'Prometeo Partners Program',
    useCasesNav: {
      ourUseCases: 'Nuestros Casos de Uso',
      useCasesDescription:
        'Al conectarte a nuestras APIs podrás automatizar, digitalizar y acceder a información bancaria relevante para tu empresa.',
    },
    product: {
      ourProducts: 'Nuestros Productos',
      ourProductsDescription:
        'Con nuestras APIs logramos que las empresas interoperen con los bancos de la región.',
    },
  },
  form: {
    fields: {
      email: { placeholder: 'Correo electrónico' },
      password: { placeholder: 'Contraseña' },
    },
    submitButton: {
      label: 'Ingresar',
    },
  },
  langEs: 'Español',
  langEn: 'Inglés',
  logIn: 'Iniciar Sesión',
  renewCredential: 'Actualizar Contraseña',
  buttonUpdtae: 'Actualizar',
  successUpdateExpiredPassword: 'Su contraseña se actualizo exitosamente ',
  alert:
    'Su contraseña expiró, tiene que actualizarla para poder acceder nuevamente',
  haveAccount: '¿No tienes una cuenta?',
  signUp: 'Registro',
  forgotPassword: '¿Olvidaste tu contraseña?',
  recoverPassword: 'Recupera tu contraseña',
  authSection: {
    loginQuestion: '¿Ya tienes una cuenta?',
    registerQuestion: '¿No tienes una cuenta?',
    loginLink: 'Ingresa aquí',
    registerLink: 'Regístrate',
  },
  error: {
    length: 'La contraseña debe tener al menos 8 caracteres',
    lengthIdExterno: 'El ID externo no debe superar los 22 caracteres',
    regex:
      'Su contraseña debe contener caracteres en mayúsculas, en minúsculas, números y caracteres especiales.',
    match: 'La contraseña no coincide',
    generic:
      'No se pudo modificar la contraseña, verifique su correo electrónico',
  },
}
