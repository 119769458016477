import { FC } from 'react'
import classNames from 'classnames'

import { getStyledElementProps } from '@lib-utils'
import type { ButtonProps } from '@lib-types'

import styles from './button.module.scss'

export const Button: FC<ButtonProps> = ({
  fullWidth = false,
  variant = 'primary',
  type = 'button',
  size = 'md',
  disabled,
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles['root'], styles[variant], styles[`size-${size}`], {
      'not-allowed': disabled,
      'pui-sizing-full-width': fullWidth,
    })
  )

  return <button type={type} {...styledProps} disabled={disabled} />
}
