import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Spacer,
  Typography,
  Button,
  BulletIconMoon,
  CheckboxLabeled,
  Anchor,
} from '@prometeoapi/afrodita-react-component-lib'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import styles from './register.module.scss'
import { useRegisterAccountUseCases } from 'src/service-locator'
import { useState } from 'react'
import { HeaderAuth, InputPassword, InputText } from 'src/components'
import { useExternalScript } from 'src/adapters'
import { HUBSPOT_TRACKING_URL } from 'src/config'

interface IFormInput {
  email: string
}

export const RegisterPage = () => {
  const { t } = useTranslation(['register', 'shared', 'auth'])
  useExternalScript({
    url: HUBSPOT_TRACKING_URL,
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [checkTerm, setCheckTerm] = useState(false)
  const { createAccount } = useRegisterAccountUseCases()
  const [registerSuccess, setRegisterSuccess] = useState(false)

  const formSchema = Yup.object({
    name: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t(`register:registerPage.nameInput`),
      })
    ),
    lastName: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t(`register:registerPage.lastNameInput`),
      })
    ),
    email: Yup.string()
      .email(
        t('shared:form.errors.emailField', {
          name: t(`register:registerPage.emailInput`),
        })
      )
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.emailInput`),
        })
      ),
    password: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.password`),
        })
      )
      .min(
        8,
        t('auth:error.length', {
          name: t(`register:registerPage.password`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.password`),
        })
      ),
    confirmPassword: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .min(
        8,
        t('resetpage:resetPasswordConfirm.error.length', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .oneOf(
        [Yup.ref('password')],
        t('resetpage:resetPasswordConfirm.error.match', {
          name: t(`register:registerPage.confirmPassword`),
        })
      ),
    terms_and_conditions: Yup.boolean().oneOf(
      [true],
      t('shared:form.errors.requiredField', {
        name: t(`register:registerPage.termAndCondtition`),
      })
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      terms_and_conditions: false,
    },
    resolver: yupResolver(formSchema),
  })

  const registerAction = async (data: any) => {
    const payload = {
      first_name: data.name,
      last_name: data.lastName,
      password: data.password,
      email: data.email,
      terms_conditions: checkTerm,
    }
    try {
      await createAccount(payload)
      setRegisterSuccess(true)
      reset()
    } catch (error: any) {
      if (errors) {
        setEmailError(error.response.data?.email || null)
      }
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    checkTerm
      ? registerAction(data)
      : setErrorMessage(t(`register:registerPage.errorTerm`))
  }

  return (
    <div>
      <HeaderAuth />

      <section className={styles['page-container']}>
        <div>
          <div>
            <Spacer size={'10x'} />
            <Typography variant="display-3" withFontFamily>
              {t(`register:registerPage.title`)}
            </Typography>
            <Spacer size={'10x'} />
          </div>
          <div className={styles['row-fields']}>
            {registerSuccess === false ? (
              <div className={styles['form-section']}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles['row-fields']}>
                    <div className={styles['field-name']}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label={t(`register:registerPage.nameInput`)}
                            type="text"
                            {...field}
                            error={errors?.name?.message}
                          />
                        )}
                      />
                    </div>
                    <div id="lastName" className={styles['field-lastname']}>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label={t(`register:registerPage.lastNameInput`)}
                            type="text"
                            {...field}
                            error={errors?.lastName?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles['full-with']}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label={t(`register:registerPage.emailInput`)}
                          type="email"
                          {...field}
                          error={emailError || errors?.email?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles['full-with']}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <InputPassword
                          {...field}
                          type="password"
                          label={t(`register:registerPage.password`)}
                          error={errors?.password?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles['full-with']}>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field }) => (
                        <InputPassword
                          {...field}
                          type="password"
                          label={t(`register:registerPage.confirmPassword`)}
                          error={errors?.confirmPassword?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles['full-with']}>
                    <Controller
                      name="terms_and_conditions"
                      control={control}
                      render={({ field }) => (
                        <CheckboxLabeled
                          checked={checkTerm}
                          label={
                            <p>
                              {t(`register:registerPage.textTerm`)}
                              <Anchor
                                href="https://prometeoapi.com/terminos-y-condiciones"
                                target="_blank"
                                id="anchor-component"
                                typographyColor="brand"
                                typographyTag="span"
                                typographyVariant="body-1"
                                underlineOnHover
                              >
                                {t(`register:registerPage.term`)}{' '}
                              </Anchor>
                              {t(`register:registerPage.andTerm`)}{' '}
                              <Anchor
                                href="https://prometeoapi.com/politica-de-privacidad"
                                target="_blank"
                                id="anchor-component"
                                typographyColor="brand"
                                typographyTag="span"
                                typographyVariant="body-1"
                                underlineOnHover
                              >
                                {t(`register:registerPage.condition`)}
                              </Anchor>
                            </p>
                          }
                          onChange={(e: any) => {
                            field.onChange(setCheckTerm(e.target.checked))
                          }}
                        />
                      )}
                    />
                    {errors.terms_and_conditions ? (
                      <p className={styles['error-text']}>
                        {errors.terms_and_conditions?.message}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>

                  {errorMessage ? (
                    <p className={styles['error-text']}>{errorMessage} </p>
                  ) : (
                    ''
                  )}
                  <Spacer size={'10x'} />
                  <div className={styles['button-container']}>
                    <Button type={'submit'} fullWidth>
                      {t(`register:registerPage.button`)}{' '}
                    </Button>
                  </div>
                </form>
                <Spacer size={'10x'} />
                <div>
                  <Typography variant={'body-1'} tag={'span'}>
                    {t('auth:authSection.loginQuestion')}
                  </Typography>{' '}
                  <Anchor href={'/'}>{t('auth:authSection.loginLink')}</Anchor>
                </div>

                <Spacer variant={'vertical'} size={'3x'} />

                <div>
                  <Typography variant={'body-1'} tag={'span'}>
                    {t('auth:forgotPassword')}
                  </Typography>{' '}
                  <Anchor href={'/reset-password'}>
                    {t('auth:recoverPassword')}
                  </Anchor>
                </div>
              </div>
            ) : (
              <div className={styles['info-section']}>
                <BulletIconMoon
                  iconPosition="start"
                  iconName="icon-check-circle"
                  className={styles['bullet-section']}
                  size="lg"
                >
                  <Typography variant="featured-3" withFontFamily>
                    {t(`register:infoSection.thanks`)}
                  </Typography>
                </BulletIconMoon>
                <Spacer size={'5x'} />
                <div className={styles['icon-line']}>
                  <Typography variant="body-1">
                    {t(`register:infoSection.successMessage`)}
                  </Typography>
                </div>
                <Spacer size={'8x'} />
                <hr />
                <Spacer size={'10x'} />
                <Typography variant="featured-3" withFontFamily>
                  {t(`register:infoSection.successQuestion`)}
                </Typography>
                <Spacer size={'10x'} />
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                    className={styles['bullet-section']}
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle1`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText1`)}
                  </Typography>
                </div>
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle2`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText2`)}
                  </Typography>
                </div>
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle3`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText3`)}
                  </Typography>
                </div>
                <Spacer size={'10x'} />
              </div>
            )}

            {registerSuccess === false ? (
              <div className={styles['info-section']}>
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                    className={styles['bullet-section']}
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle1`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText1`)}
                  </Typography>
                </div>
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle2`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText2`)}
                  </Typography>
                </div>
                <div className={styles['icon-line']}>
                  <BulletIconMoon
                    iconPosition="start"
                    iconName="icon-check-circle-outline"
                  >
                    <Typography variant="body-1">
                      {t(`register:infoSection.subtitle3`)}
                    </Typography>
                  </BulletIconMoon>
                  <Typography
                    className={styles['info-section-help']}
                    typographyColor="gray-4"
                    variant="body-2"
                    withFontFamily
                  >
                    {t(`register:infoSection.helpText3`)}
                  </Typography>
                </div>
                <Spacer size={'10x'} />

                <hr />
                <Spacer size={'10x'} />
                <Typography variant="featured-3" withFontFamily>
                  {t(`register:infoSection.question`)}
                </Typography>
                <Spacer size={'10x'} />

                <BulletIconMoon
                  iconPosition="start"
                  iconName="icon-help-bubble"
                  iconSize="md"
                >
                  <Anchor
                    href="mailto:ventas@prometeoapi.com"
                    id="anchor-component"
                    typographyColor="brand"
                    typographyTag="span"
                    typographyVariant="body-1"
                    underlineOnHover
                  >
                    <Typography variant="body-1" typographyColor="red-5">
                      {t(`register:infoSection.contact`)}
                    </Typography>
                  </Anchor>
                </BulletIconMoon>

                <Spacer size={'10x'} />

                <BulletIconMoon
                  iconPosition="start"
                  iconName="icon-doc-link"
                  iconSize="md"
                >
                  <Anchor
                    href="https://prometeoapi.readme.io/docs/te-damos-la-bienvenida-a-prometeo-docs"
                    target="_blank"
                    id="anchor-component"
                    typographyColor="brand"
                    typographyTag="span"
                    typographyVariant="body-1"
                    underlineOnHover
                  >
                    <Typography variant="body-1" typographyColor="red-5">
                      {t(`register:infoSection.doc`)}
                    </Typography>
                  </Anchor>
                </BulletIconMoon>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  )
}
