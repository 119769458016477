import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './auth-section.module.scss'
import { Anchor, Typography } from '@prometeoapi/afrodita-react-component-lib'

export const AuthSection: FC = () => {
  const { t } = useTranslation(['resetpage'])

  return (
    <div>
      <div className={styles['row-fields']}>
        <Typography variant="body-1" withFontFamily>
          {t(`resetpage:resetPage.loginQuestion`)}{' '}
          <Anchor href="/" underlineOnHover>
            {t(`resetpage:resetPage.loginLink`)}
          </Anchor>
        </Typography>
      </div>
      <div className={styles['row-fields']}>
        <Typography variant="body-1" withFontFamily>
          {t(`resetpage:resetPage.registerQuestion`)}{' '}
          <Anchor href="/register-account" underlineOnHover>
            {t(`resetpage:resetPage.registerLink`)}
          </Anchor>
        </Typography>
      </div>
    </div>
  )
}
