export default {
  registerPage: {
    title: 'Build with Prometeo',
    nameInput: 'Name',
    lastNameInput: 'LastName',
    emailInput: 'Email',
    password: 'Password',
    confirmPassword: 'Repeat Password',
    usageSelect: 'What are you building with Prometeo?',
    roleSelect: 'What role do you play in your company?',
    countrySelected: 'Country where your company is incorporated',
    termAndCondtition: 'Terms of use and privacy conditions',
    button: 'Start',
    textTerm: 'I accept the ',
    andTerm: 'and',
    term: 'terms of use',
    condition: 'privacy conditions',
    errorTerm: 'You should accept terms of use and privacy policy',
  },
  infoSection: {
    title: 'When you create your account you will be able to:',
    thanks: 'Thank you for signing up!',
    successMessage:
      'We are almost ready, for your security we need to verify your email address. Please go to your inbox and confirm your email.',
    successQuestion:
      'What will you be able to do as soon as your mail is confirmed?',
    subtitle1: 'Access our APIs.',
    helpText1: 'Of information, transactions and payments.',
    subtitle2: 'Try our APIs.',
    helpText2: 'In our Sandbox with dummy data.',
    subtitle3: 'Build your solution.',
    helpText3: 'With real data in a production environment.',
    question: 'Do you have questions?',
    contact: ' Contact our commercial team through ventas@prometeoapi.com',
    doc: 'Go to the documentation of our APIs ',
  },
}
