export default {
  registerPage: {
    title: 'Construye con Prometeo',
    nameInput: 'Nombre',
    lastNameInput: 'Apellido',
    emailInput: 'Email corporativo',
    password: 'Contraseña',
    confirmPassword: 'Repetir Contraseña',
    usageSelect: '¿Qué estás construyendo con Prometeo?',
    roleSelect: '¿Qué rol cumples en tu empresa?',
    countrySelected: 'País donde está constituída tu empresa',
    termAndCondtition: 'Términos de uso y condiciones de privacidad',
    button: 'Comenzar',
    textTerm: 'Acepto los ',
    andTerm: ' y ',
    term: 'términos de uso',
    condition: 'condiciones de privacidad',
    errorTerm: 'Debe aceptar los términos de uso y la política de privacidad.',
  },
  infoSection: {
    title: 'Al crear tu cuenta podrás:',
    thanks: '¡Gracias por registrarte!',
    successMessage:
      'Ya estamos casi listos, por tu seguridad debemos verificar tu correo electrónico. Por favor dirígete a tu bandeja de entrada y confirma tu correo.',
    successQuestion: '¿Qué podrás hacer tan pronto se confirme tu correo?',
    subtitle1: 'Una única API bancaria para Latinoamérica',
    helpText1:
      'Accede a información bancaria, valida cuentas e inicia pagos en tiempo real.',
    subtitle2: 'Fácil integración',
    helpText2:
      'Intégrate en pocos días y comienza a automatizar y escalar tu empresa.',
    subtitle3: 'Full coverage en Latinoamérica',
    helpText3:
      'Accede a más del 90% de las instituciones financieras de la región.',
    question: '¿Tienes preguntas?',
    contact: 'Contáctanos a ventas@prometeoapi.com',
    doc: 'Ir a la documentación de nuestras APIs ',
  },
}
