import { HeaderAuth, InputPassword } from 'src/components'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './renew-credentials.module.scss'
import { useUserSettingsUseCases } from 'src/service-locator'
import { useParams } from 'react-router-dom'

import {
  Spacer,
  Typography,
  Button,
  TextField,
  Anchor,
} from '@prometeoapi/afrodita-react-component-lib'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { WarningAlert } from 'src/presentation'
import { useState } from 'react'
import { Alert, AlertBody } from '@lib-components'

export const RenewCredentialsPage = () => {
  const { t } = useTranslation(['register', 'shared', 'auth'])
  const { token } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const { updateExpiredPassword } = useUserSettingsUseCases()
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const formSchema = Yup.object({
    token: Yup.string(),
    password: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.password`),
        })
      )
      .min(
        8,
        t('auth:error.length', {
          name: t(`register:registerPage.password`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.password`),
        })
      ),
    confirmPassword: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .min(
        8,
        t('auth:error.length', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .oneOf(
        [Yup.ref('password')],
        t('resetpage:resetPasswordConfirm.error.match', {
          name: t(`register:registerPage.confirmPassword`),
        })
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      token: token,
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(formSchema),
  })

  const updatePasswordAction = async (data: any) => {
    const payload = {
      token: data.token,
      password: data.password,
    }
    try {
      await updateExpiredPassword(payload)
      setUpdateSuccess(true)
      reset()
    } catch (error: any) {
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    updatePasswordAction(data)
  }

  return (
    <div>
      <HeaderAuth />
      <Spacer size={'10x'} />

      <section className={styles['page-container']}>
        <div>
          {updateSuccess ? (
            <Alert variant={'success'}>
              <AlertBody>{t('auth:successUpdateExpiredPassword')}</AlertBody>
            </Alert>
          ) : (
            <WarningAlert>{t('auth:alert')}</WarningAlert>
          )}

          <Spacer size={'5x'} />
          <Typography variant={'display-3'}>
            {t('auth:renewCredential')}
          </Typography>
          <Spacer size={'10x'} />
          <>
            <div className={styles['form-section']}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles['row-fields']}></div>
                <div className={styles['full-with']}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputPassword
                        {...field}
                        type="password"
                        label={t(`register:registerPage.password`)}
                        error={errors?.password?.message}
                      />
                    )}
                  />
                </div>
                <div className={styles['full-with']}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <InputPassword
                        {...field}
                        type="password"
                        label={t(`register:registerPage.confirmPassword`)}
                        error={errors?.confirmPassword?.message}
                      />
                    )}
                  />
                </div>
                <div className={styles['full-with']}>
                  <Controller
                    name="token"
                    control={control}
                    render={({ field }) => (
                      <TextField type="hidden" {...field} />
                    )}
                  />
                </div>
                {errorMessage ? (
                  <p className={styles['error-text']}>{errorMessage} </p>
                ) : (
                  ''
                )}
                <Spacer size={'10x'} />
                <div className={styles['button-container']}>
                  <Button type={'submit'} fullWidth>
                    {t('auth:buttonUpdtae')}{' '}
                  </Button>
                </div>
              </form>
              <Spacer size={'10x'} />
              <div>
                <Typography variant={'body-1'} tag={'span'}>
                  {t('auth:authSection.loginQuestion')}
                </Typography>{' '}
                <Anchor href={'/'}>{t('auth:authSection.loginLink')}</Anchor>
              </div>

              <Spacer variant={'vertical'} size={'3x'} />

              <div>
                <Typography variant={'body-1'} tag={'span'}>
                  {t('auth:forgotPassword')}
                </Typography>{' '}
                <Anchor href={'/reset-password'}>
                  {t('auth:recoverPassword')}
                </Anchor>
              </div>
            </div>
          </>
        </div>
      </section>
    </div>
  )
}
