export default {
  resetPage: {
    title: 'Restablecer Contraseña',
    emailInputh: 'Correo Electrónico',
    loginQuestion: '¿Ya tienes una cuenta?',
    registerQuestion: '¿No tienes una cuenta?',
    loginLink: 'Ingresa aquí',
    registerLink: 'Regístrate',
    successMessage:
      'Si tu cuenta está registrada con el correo electrónico que ingresaste, pronto recibirás las instrucciones para restablecer tu contraseña.',
  },
  resetPassworEmail: {
    greetings: 'Hola, ',
    message:
      ' Recibimos tu solicitud para restablecer tu contraseña, por favor haz click en el botón para elegir una nueva.',
    choosePassword: 'Elige una nueva contraseña',
    errorButton: 'Si el botón no funciona, haz click en este link: ',
  },
  resetPasswordConfirm: {
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar tu nueva contraseña',
    errorMessage: 'No se pudo hacer el cambio de contraseña',
    form: {
      fields: {
        newPassword: {
          label: 'Nueva Contraseña',
        },
        confirmPassword: {
          label: 'Confirmar tu nueva contraseña',
        },
      },
    },
    error: {
      length: 'La contraseña debe tener al menos 8 caracteres',
      regex:
        'Su contraseña debe contener caracteres en mayúsculas, en minúsculas, números y caracteres especiales.',
      match: 'La contraseña no coincide',
      generic:
        'No se pudo modificar la contraseña, verifique su correo electrónico',
    },
  },
}
